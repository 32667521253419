import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8e73fb6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-medium" }
const _hoisted_2 = ["src", "alt", "width", "height"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: _ctx.linkPath,
      class: _normalizeClass(_ctx.btnClass)
    }, {
      default: _withCtx(() => [
        (_ctx.imageName)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: require(`../../assets/images/${_ctx.imageName}`),
              alt: _ctx.text,
              width: _ctx.imageWidth,
              height: _ctx.imageHeigth
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.text), 1))
      ]),
      _: 1
    }, 8, ["to", "class"])
  ]))
}