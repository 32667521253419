<template>
  <div class="btn-medium">
    <router-link
      :to="linkPath"
      :class="btnClass">
      <img
        v-if="imageName"
        :src="require(`../../assets/images/${imageName}`)"
        :alt="text" :width="imageWidth"
        :height="imageHeigth">
      <span v-else>{{ text }}</span>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    imageName: {
      type: String,
      default: null
    },
    imageWidth: {
      type: String,
      default: null
    },
    imageHeigth: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    linkPath: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: "auto"
    },
    btnClass: {
      type: String,
      default: null
    },
  }
});
</script>

<style scoped lang="stylus">
.btn-medium
  padding 10px 0
  a
    display inline-block
    border 1px solid #000
    text-align center
    padding 10px 10px 10px 10px
    border-radius 10px
    opacity 1
    transition all .1s ease-out
    .arrow
      display inline-block
      margin-left 5px
      margin-top -8px
      transition all .1s ease-out

    &:hover
      opacity 0.5
      transform scale(1.03)
      
    &.recruit-page-btn
      background-color #FFF462
      padding 10px 10px 10px 10px
      width 190px
      .arrow
        margin-left 20px
</style>
